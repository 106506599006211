import {apiRequest, IRequestOpts} from "@/lib/API";
import {ISellerDataType} from "@/data/rentalioTypes";
import {ICancelOption} from "@/app/(konto)/konto/transakcje/transactionCancelled";

export const SELLERS_GET_SINGLE_BY_PROFILE_NAME = (
  data: any
) => apiRequest.POST(
  '/sellers/get/single/by/profileName',
  undefined,
  data
);

export const SELLERS_UPDATE_ACCOUNT_ADDRESS = (
  data: any,
): Promise<ISellerDataType> => apiRequest.POST('/seller/account/address/update', undefined, data, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true,
  fetchOnly: true,
});

export const SELLERS_REMOVE_ACCOUNT_ADDRESS = (
  data: any,
): Promise<ISellerDataType> => apiRequest.POST('/seller/account/address/remove', undefined, data, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true,
  fetchOnly: true,
});

export const SELLERS_ADDRESS_SET_DEFAULT = (
  data: any,
): Promise<ISellerDataType> => apiRequest.POST('/seller/account/address/setDefault', undefined, data, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true,
  fetchOnly: true,
});

export const SELLERS_NEW_ACCOUNT_ADDRESS = (
  data: any,
): Promise<ISellerDataType> => apiRequest.POST('/seller/account/address/new', undefined, data, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true,
  fetchOnly: true,
});
export const SELLERS_UPDATE_ACCOUNT = (data: any, errCallback?: any) => apiRequest.POST('/sellers/update/account', undefined, data, undefined, errCallback, {
  unwrapData: true,
  addTokenToHeader: true
});
export const SELLER_REMOVE_ACCOUNT = () => apiRequest.POST('/auth/account/remove', undefined, undefined, undefined, undefined, {
  addTokenToHeader: true,
  fetchOnly: true,
});
export const SELLERS_UPDATE_NEWSLETTER = (data: any) => apiRequest.POST('/sellers/update/newsletter', undefined, data, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true,
  fetchOnly: true,
});
export const SELLERS_UPDATE_INSURANCE = () => apiRequest.POST('/sellers/update/insurance', undefined, undefined, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true,
  fetchOnly: true,
});
export const SELLERS_CHECK_PROFILE_NAME = (data: any) => apiRequest.POST('/sellers/check/profileName', undefined, data, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true,
  fetchOnly: true,
});
export const SELLER_CREATE_GEAR = (data: any) => apiRequest.POST('/gears/create', undefined, data, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true,
  fetchOnly: true,
});
export const SELLER_UPDATE_GEAR = (data: any) => apiRequest.POST('/gears/update', undefined, data, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true
});
export const SELLER_REMOVE_GEAR = (data: any) => apiRequest.POST('/gears/delete', undefined, data, undefined, undefined, {
  addTokenToHeader: true,
  fetchOnly: true,
});
export const SELLER_GEAR_GET_OWN = (data: any) => apiRequest.POST('/gears/get/sellers/all', undefined, data, undefined, undefined, {
  addTokenToHeader: true
});
export const SELLER_GEAR_UPLOAD_PHOTO = (data: any) => apiRequest.POST('/gears/files/upload', undefined, data, undefined, undefined, {
  formData: true,
  addTokenToHeader: true,
  fetchOnly: true,
});
export const SELLER_UPLOAD_PHOTO = (data: any) => apiRequest.POST('/sellers/files/upload', undefined, data, undefined, undefined, {
  formData: true,
  addTokenToHeader: true,
  // fetchOnly: true,
});
export const SELLER_GEAR_DELETE_PHOTO = (data: any) => apiRequest.POST('/gears/files/delete', undefined, data, undefined, undefined, {
  addTokenToHeader: true,
  fetchOnly: true,
});
export const SELLER_GEAR_SET_MAIN_PHOTO = (data: any) => apiRequest.POST('/gears/files/setMain', undefined, data, undefined, undefined, {
  addTokenToHeader: true,
  fetchOnly: true,
});
export const SELLER_GEAR_SET_ACTIVE = (data: any) => apiRequest.POST('/gears/setActive', undefined, data, undefined, undefined, {
  addTokenToHeader: true,
  fetchOnly: true,
});
export const SELLER_TRANSACTIONS_GET_ALL_AS_OWNER = () => apiRequest.POST('/transactions/get/all/asOwner', undefined, {}, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true
});
export const SELLER_TRANSACTIONS_GET_ALL_AS_TENANT = () => apiRequest.POST('/transactions/get/all/asTenant', undefined, {}, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true
});

export const SELLER_TRANSACTIONS_GET_SINGLE = (data: any, opts: IRequestOpts) => apiRequest.POST(
  '/transactions/get/single',
  undefined,
  data,
  undefined,
  undefined,
  {
    unwrapData: true,
    addTokenToHeader: true,
    ...opts
  });

export const SELLER_TRANSACTIONS_GET_AGREEMENT_FOR_GEAR = (data: any, token: string) => apiRequest.POST(
  '/transaction/generate/gearAgreement/empty',
  undefined,
  data,
  {
    'x-access-token': token
  },
  undefined,
  {
    unwrapData: true,
    addTokenToHeader: true,
    fetchOnly: true
  });
export const SELLER_TRANSACTION_CREATE = (data: any) => apiRequest.POST('/transaction/create', undefined, data, undefined, undefined, {
  fetchOnly: true,
  // unwrapData: true,
  addTokenToHeader: true
});

export const SELLER_TRANSACTION_RENT_CONFIRM = (data: any) => apiRequest.POST('/transaction/rent/confirm', undefined, data, undefined, undefined, {
  fetchOnly: true,
  // unwrapData: true,
  addTokenToHeader: true
});
export const SELLER_TRANSACTION_RENT_CANCEL = (data: any) => apiRequest.POST('/transaction/rent/cancel', undefined, data, undefined, undefined, {
  fetchOnly: true,
  // unwrapData: true,
  addTokenToHeader: true
});
export const SELLER_TRANSACTION_RENT_CANCEL_REASON = (data: ICancelOption) => apiRequest.POST('/transaction/rent/cancel/reason', undefined, data, undefined, undefined, {
  fetchOnly: true,
  // unwrapData: true,
  addTokenToHeader: true
});

export const SELLER_TRANSACTION_RENT_DONE = (data: any) => apiRequest.POST('/transaction/rent/done', undefined, data, undefined, undefined, {
  fetchOnly: true,
  // unwrapData: true,
  addTokenToHeader: true
});
export const SELLER_TRANSACTION_RENT_REVIEW_OWNER = (data: any) => apiRequest.POST('/transaction/rent/review/owner', undefined, data, undefined, undefined, {
  fetchOnly: true,
  // unwrapData: true,
  addTokenToHeader: true
});
export const SELLER_TRANSACTION_RENT_REVIEW_TENANT = (data: any) => apiRequest.POST('/transaction/rent/review/tenant', undefined, data, undefined, undefined, {
  fetchOnly: true,
  // unwrapData: true,
  addTokenToHeader: true
});
export const SELLER_NOTIFICATIONS_GET = (data?: any) => apiRequest.POST('/notifications/get', undefined, data, undefined, undefined, {
  unwrapData: true,
  addTokenToHeader: true
});
export const SELLER_NOTIFICATIONS_READ = (data?: any) => apiRequest.POST('/notifications/read', undefined, data, undefined, undefined, {
  fetchOnly: true,
  unwrapData: true,
  addTokenToHeader: true
});