import {apiRequest, IRequestOpts} from "@/lib/API";
import {ISellerDataType} from "@/data/rentalioTypes";

export const AUTH_LOGIN_CLASSIC = (email: string, password: string) => apiRequest.POST(
  `/auth/login/classic`,
  undefined,
  {
    email,
    password
  },
  undefined,
  undefined,
  {
    fetchOnly: true,
  })

export const AUTH_LOGOUT = (token?: string) => apiRequest.POST(
  `/auth/logout`,
  undefined,
  undefined,
  {
    'x-access-token': token
  },
  undefined,
  {
    fetchOnly: true,
    addTokenToHeader: true
  })

export const AUTH_SEND_VERIFICATION_EMAIL = (userToken: string) => apiRequest.POST(
  `/seller/verify/create`,
  undefined,
  undefined,
  {
    'x-access-token': userToken
  },
  undefined,
  {
    fetchOnly: true,
    addTokenToHeader: true
  })
export const AUTH_VERIFY_SELLER_EMAIL_BY_CODE = (verificationCode?: string) => apiRequest.POST(
  `/seller/verify`,
  undefined,
  {
    verificationCode
  },
  undefined,
  undefined,
  {
    fetchOnly: true
  })

export const AUTH_VERIFY_LEVEL_BASIC = (token: string) => apiRequest.POST(
  `/seller/verify/basic`,
  undefined,
  undefined,
  {
    'x-access-token': token
  },
  undefined,
  {
    fetchOnly: true,
    addTokenToHeader: true
  })

export const AUTH_VERIFY_LEVEL_PRO_CHECK = (token: string) => apiRequest.POST(
  `/seller/verify/pro/check`,
  undefined,
  undefined,
  {
    'x-access-token': token
  },
  undefined,
  {
    fetchOnly: true,
    addTokenToHeader: true
  })

export const AUTH_VERIFY_LEVEL_PRO_DOWNLOAD_DECLARATION = (token: string) => apiRequest.POST(
  `/seller/verify/pro/declaration`,
  undefined,
  undefined,
  {
    'x-access-token': token
  },
  undefined,
  {
    fetchOnly: true,
    addTokenToHeader: true
  })

export const AUTH_GET_OWN = (
  opts?: IRequestOpts,
  headers?: any
): Promise<ISellerDataType> => apiRequest.POST(
  '/auth/get/own',
  undefined,
  undefined,
  headers || undefined,
  undefined, {
    unwrapData: true,
    addTokenToHeader: true,
    fetchOnly: true,
    ...opts
  });